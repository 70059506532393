import RootApp from "./RootApp";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import React from "react";

const AuthenticatedApp = () => <RootApp />;

export default withAuthenticator(AuthenticatedApp, {
  hideSignUp: true,
});
