import { ThemeProvider } from "@mui/material/styles";
import Grant from "components/Grant";
import Logout from "components/Logout";
import theme from "components/common/Theme";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

const RootApp = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route path={"/logout"} element={<Logout />} />
        <Route path={"/grant"} element={<Grant />} />
        <Route path={"*"} element={<Navigate to={"/grant"} />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

export default RootApp;
