import AuthenticatedApp from "./components/AuthenticatedApp";
import ErrorBoundary from "./components/ErrorBoundary";
import "./styles/styles.scss";
import React from "react";

const App = () => (
  <ErrorBoundary>
    <AuthenticatedApp />
  </ErrorBoundary>
);

export default App;
