import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as config from "config";
import React from "react";

const GlobalError = () => {
  return (
    <Dialog
      open={true}
      aria-labelledby={"error-dialog-title"}
      maxWidth={"lg"}
      fullWidth
      scroll={"paper"}
      className={"error-dialog"}
    >
      <DialogTitle id={"error-dialog-title"}>
        {`Please contact ${config.SUPPORT_EMAIL}.`}
      </DialogTitle>
      <DialogContent dividers={true}>
        {"Unfortunately an unexpected error has occurred."}
      </DialogContent>
    </Dialog>
  );
};

export default GlobalError;
