import SignOutButton from "./common/SignoutButton";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import MDEditor from "@uiw/react-md-editor";
import { Auth } from "aws-amplify";
import * as config from "config";
import { useState } from "react";

const ALLOWED_CATEGORIES = [
  {
    value: "BAU",
    tooltip: "A process that should be replaced with standard tooling over time.",
  },
  {
    value: "Exceptional",
    tooltip: "An exceptional function that is likely to stay as required break glass for the foreseeable future.",
  },
  {
    value: "Audit",
    tooltip: "A category for items which are validating that the break glass system works etc.",
  },
];

const getSession = async () => {
  const session = await Auth.currentSession();
  const preExpTime = 900;
  const expiryTime = session.getIdToken().getExpiration();
  const now = Math.round(Date.now() / 1000);
  if (preExpTime + now > expiryTime) {
    const user = await Auth.currentAuthenticatedUser();
    await new Promise((resolve) =>
      user.refreshSession(session.refreshToken, resolve)
    );
    return await Auth.currentSession();
  }
  return session;
};

const Grant = () => {
  const [reason, setReason] = useState("");
  const [category, setCategory] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const hasReason = (reason || "").trim() !== "";
  const hasCategory = (category || "").trim() !== "";
  return (
    <div className={"app"}>
      <div className={"dashboard"}>
        <div className={"dashboard-content"}>
          <div className={"container"} data-color-mode={"light"}>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              marginTop={1}
              marginBottom={3}
            >
              <Typography variant={"h4"}>
                {`Gatekeeper (${config.ROOT_STEM})`}
              </Typography>
              <SignOutButton />
            </Stack>

            <Stack spacing={0.75}>
              <Typography>{"Reason for access:"}</Typography>
              <MDEditor value={reason} onChange={setReason} />
              <FormControl>
                <FormLabel>Category</FormLabel>
                <RadioGroup 
                  row
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {ALLOWED_CATEGORIES.map((category) => (
                    <Tooltip title={category.tooltip}>
                      <FormControlLabel
                        key={category.value}
                        value={category.value}
                        label={category.value}
                        control={<Radio />}
                      />
                    </Tooltip>
                  ))}
                </RadioGroup>
              </FormControl>
              <Button
                variant={"contained"}
                disabled={!hasReason || !hasCategory || loading}
                sx={{
                  width: "11rem",
                  height: "2.5rem",
                  marginTop: "0.75rem !important",
                  alignSelf: "flex-end",
                }}
                onClick={async () => {
                  setResponse("");
                  setLoading(true);
                  const session = await getSession();
                  const token = session.getIdToken().getJwtToken();
                  const url = `${config.ROOT_STEM}/break-glass/grant`;
                  const payload = {
                    method: "post",
                    cache: "no-cache",
                    body: JSON.stringify({ reason, category }),
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: token,
                    },
                  };
                  const response = await fetch(url, payload);
                  const json = await response.json();
                  setLoading(false);
                  if (json?.type === "markdown") {
                    setResponse(json?.message);
                  } else {
                    setResponse(
                      `\`\`\`\n${JSON.stringify(json, null, 2)}\n\`\`\``
                    );
                  }
                }}
              >
                {loading ? <Box className={"loading"} /> : "Request Access"}
              </Button>
            </Stack>

            {response && (
              <Stack spacing={0.75} marginTop={5}>
                <Typography>{"Response:"}</Typography>
                <Paper sx={{ padding: 2 }}>
                  <MDEditor.Markdown source={response} />
                </Paper>
              </Stack>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Grant;
