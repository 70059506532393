import GlobalError from "./error/GlobalError";
import * as Sentry from "@sentry/react";
import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.addBreadcrumb({
      message: `Master error boundary.`,
    });
    Sentry.captureException(error);
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return <GlobalError />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
