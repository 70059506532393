import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SignOutButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={(e) => {
        navigate("/logout");
        e.preventDefault();
      }}
    >
      {"Sign Out"}
    </Button>
  );
};

export default SignOutButton;
